@import "../../custom.scss";


.table-header{
  background-color: var(--primary);

  th{
    background-color: var(--primary);
    color: white;
  }
}
