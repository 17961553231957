.button-tabs > button{
    width: 100%;
}

.button-tabs > button:first-of-type{
    border-radius: 20px 0 0 20px;

}

.button-tabs  > button:last-of-type{
    border-radius: 0 20px 20px 0 ;
}
